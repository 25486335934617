<template>
    <div class="pvhFactory" id="purchaseOrd">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:10px;">{{ $t('PURCHASE ORDER') }}</div>
            </div>
            <template>
                <el-row v-if="!isAdvancedSearch" class="topFromSearch">
                    <el-col class="formDiv" :xs="24" :sm="18" :md="18" :lg="18" :xl="18" style="align-self: flex-end; ">
                        <el-input style="width:550px;" v-model="SeaCodeVal"
                            :placeholder="$t('PLEASE ENTER THE KEYWORDS TO BE SEARCHED')" @click="searchFrom">
                        </el-input>
                        <el-badge :value="selectBadge" style="margin-right: 18px;">
                            <el-button plain icon="iconfont shaixuan2" size="mini" :title="$t('Display Filter')"
                                @click="isAdvancedSearch = true;">{{ $t('Filter') }}</el-button>
                        </el-badge>
                        <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary"
                            @click="searchFrom"> {{ $t('Search') }}</el-button>
                    </el-col>
                    <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6"
                        style="padding-top: 20px!important; text-align: right; align-self: flex-end;">
                        <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain
                            @click="addPurOrd"> {{ $t('New') }}</el-button>
                        <el-button type="primary" icon="iconfont daoru6" size="mini" :title="$t('Import')"
                            v-if="!isBrandBusiness" plain @click="openimport"> {{ $t('Import') }}</el-button>
                        <el-button type="primary" icon="iconfont daochu2" size="mini" :title="$t('Export')" plain
                            @click="exportOrder"> {{ $t('Export') }}</el-button>
                    </el-col>
                </el-row>

                <el-row :gutter="20" class="topFromSearch" v-if="isAdvancedSearch">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="height: auto;">
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span
                                :style="SeaCodeVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                        $t('KEYWORDS')
                                }}:</span>
                            <el-input @focus="focusDiv($event)" @blur="blurDiv($event)" v-model="SeaCodeVal"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span
                                :style="poVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                        isBrandBusiness ?
                                            $t('MASTER PO NUMBER') : $t('PO')
                                }}:</span>
                            <el-input v-model="poVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span
                                :style="codeVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                        isBrandBusiness ?
                                            $t('MASTER STYLE NUMBER') : $t('FINISHED ITEM NUMBER')
                                }}:</span>
                            <el-input v-model="codeVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span id="venSpan"
                                :style="vendorVal.ids.length == 0 ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                        isBrandBusiness ?
                                            $t('VENDOR') : $t('SUPPLIER')
                                }}:</span>
                            <el-select v-model="vendorVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                multiple collapse-tags placeholder="" style="width:100%;" @change="venchang">
                                <el-option v-for="item in this.vendorData" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4" v-if="!isBrandBusiness">
                            <span id="otherStatusSpan"
                                :style="otherStatusVal == null || otherStatusVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                        $t('STATE')
                                }}:</span>
                            <el-select v-model="otherStatusVal" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                collapse-tags placeholder="" style="width:100%;" @change="otherStatuschang" clearable>
                                <el-option v-for="item in this.traingStatusData" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4" v-if="isBrandBusiness">
                            <span id="traingStatusSpan"
                                :style="traingStatusVal == null || traingStatusVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                        $t('STATE')
                                }}:</span>
                            <el-select v-model="traingStatusVal" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                collapse-tags placeholder="" style="width:100%;" @change="traingStatuschang" clearable>
                                <el-option v-for="item in this.traingStatusData" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span
                                :style="businessnoinVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{$t('BUSINESS SERIAL NUMBER INTERNAL')}}:</span>
                            <el-input v-model="businessnoinVal" @focus="focusDiv($event)" @blur="blurDiv($event)">
                            </el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span
                                :style="businessnooutVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{ $t('BUSINESS SERIAL NUMBER EXTERNAL')}}:</span>
                            <el-input v-model="businessnooutVal" @focus="focusDiv($event)" @blur="blurDiv($event)">
                            </el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span
                                :style="batchVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                        $t('BATCH')
                                }}:</span>
                            <el-input v-model="batchVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span
                                :style="beginTimeVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                        $t('ORDERBEINGTIME')
                                }}:</span>
                            <el-date-picker class="dataicon" v-model="beginTimeVal" type="date"
                                @focus="focusDiv($event)" @blur="blurDiv($event)" format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span
                                :style="endTimeVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                        $t('ORDERENDTIME')
                                }}:</span>
                            <el-date-picker class="dataicon" v-model="endTimeVal" type="date" @focus="focusDiv($event)"
                                @blur="blurDiv($event)" format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon=""
                                style="width:100%;">
                            </el-date-picker>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span
                                :style="colorVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                        $t('COLOR')
                                }}:</span>
                            <el-input v-model="colorVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span
                                :style="RemarkVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                        $t('REMARK')
                                }}:</span>
                            <el-input v-model="RemarkVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span
                                :style="UTbeginTimeVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{$t('UPDATE START DATE')}}:</span>
                            <el-date-picker class="dataicon" v-model.trim="UTbeginTimeVal" type="date"
                                @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span
                                :style="UTendTimeVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{$t('UPDATE END DATE')}}:</span>
                            <el-date-picker class="dataicon" v-model.trim="UTendTimeVal" type="date"
                                @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                    </el-col>

                </el-row>
                <el-row :gutter="20" class="topFromSearch" v-if="isAdvancedSearch">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                        style="text-align: right; align-self: flex-end;">
                        <el-button plain icon="iconfont shaixuan1" size="mini" :title="$t('Hidden Filter')"
                            @click="isAdvancedSearch = false; getSelectBadge()"> {{ $t('Hidden Filter') }} </el-button>
                        <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary"
                            @click="searchFrom"> {{ $t('Search') }}</el-button>
                        <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain
                            @click="addPurOrd"> {{ $t('New') }}</el-button>
                        <el-button type="primary" icon="iconfont daoru6" size="mini" :title="$t('Import')"
                            v-if="!isBrandBusiness" plain @click="openimport"> {{ $t('Import') }}</el-button>
                        <el-button type="primary" icon="iconfont daochu2" size="mini" :title="$t('Export')" plain
                            @click="exportOrder"> {{ $t('Export') }}</el-button>

                    </el-col>
                </el-row>
                <avue-crud :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page"
                    @current-change="currentChange" v-loading="loading">
                    <template slot="SpeedHeader" slot-scope="{ column }">
                        <label>{{ column.label }}</label>
                        <el-tooltip class="item" effect="dark" placement="bottom">
                            <div slot="content">
                                {{ $t('purchaseTip') }}
                            </div>
                            <i class="el-icon-warning" style="margin-left: 3px; font-size: 14px"></i>
                        </el-tooltip>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="Speed">
                        <div style="display: flex; flex-direction: row;">
                            <el-tooltip class="item" effect="light" placement="bottom-start">
                                <div slot="content">
                                    <span style="font-weight: 500; color: #000; font-size: 14px;">{{ $t('To do list for this order')}}:</span>
                                    <div v-for="(item, index) in         row.PendingContent.CurrentSummaryInfos"
                                        v-bind:key="index">
                                        <span>{{ $t('No.') }}{{ item.Index }}({{ item.BusinessNoIn }})：{{ $t('to do a total')}}</span>
                                        <span
                                            style="font-weight: 600; color: #ff6a00; font-size: 12px; margin-left: 5px;">{{
                                                    item.Count
                                            }}</span>
                                    </div>

                                    <span style="font-weight: 500; color: #000; font-size: 14px;">{{ $t('To do list for other order')}}</span>
                                    <div v-for="(item, index) in         row.PendingContent.OtherSummaryInfos"
                                        v-bind:key="index">
                                        <span>{{ $t('No.') }}{{ item.Index }}({{ item.BusinessNoIn }})：{{ $t('to do a total')}}</span>
                                        <span
                                            style="font-weight: 600; color: #ff6a00; font-size: 12px; margin-left: 5px;">{{
                                                    item.Count
                                            }}</span>
                                    </div>

                                </div>
                                <el-link type="warning">{{ row.Speed }}</el-link>
                            </el-tooltip>
                            <el-tooltip class="item" effect="light" placement="bottom-start">
                                <div slot="content">
                                    <div style="display: flex; flex-direction: column;">
                                        <span v-if="row.IsToLast"
                                            style="font-weight: 500; color: #000; font-size: 14px;">{{ $t('Order All Link')}}</span>
                                    </div>
                                </div>
                                <div class="icon">
                                    <img style="width: 25px;height: 23px;margin-right: 30px;" v-if="row.IsToLast"
                                        src="/img/speed/linkAll.png" alt="" />
                                </div>
                            </el-tooltip>
                            <el-tooltip class="item" effect="light" placement="bottom-start">
                                <div slot="content">
                                    <div style="display: flex; flex-direction: column;">
                                        <span style="font-weight: 500; color: #000; font-size: 14px;">{{ $t('Order Has No Link')}}</span>
                                    </div>
                                </div>
                                <div class="icon">
                                    <img style="width: 25px;height: 23px;margin-right: 30px;"
                                        v-if="row.IsToLast == false" src="/img/speed/linkNo.png" alt="" />
                                </div>
                            </el-tooltip>
                        </div>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="TrackingApproveStatus">
                        <el-popover trigger="hover" placement="top">
                            <p> {{ $t('Statetip_' + row.TrackingApproveStatus) }}</p>
                            <div slot="reference">
                                <span style="margin-left: 10px">{{ formatterStatus(row) }}</span>
                            </div>
                        </el-popover>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="ApproveStatus">
                        <el-popover trigger="hover" placement="top">
                            <p> {{ $t('Statetip_' + row.ApproveStatus) }}</p>
                            <div slot="reference">
                                <span style="margin-left: 10px">{{ formatterTrackingStatus(row) }}</span>
                            </div>
                        </el-popover>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')"
                            @click="editPur(row)" v-if="row.Status == 0"></el-button>
                        <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')"
                            @click="delPur(row)"
                            v-if="row.Status == 0 && row.ApproveStatus < 650 && row.LockedStatus != 605">
                        </el-button>
                        <el-button icon="el-icon-refresh-left" type="text" size="medium" :title="$t('RecoverData')"
                            v-if="row.Status == 1" @click="recover(row)"></el-button>
                    </template>
                    <template slot="expand" slot-scope="props">
                        <el-table :data="props.row.Details" :header-cell-style="tableHeaderColor"
                            :row-class-name="tableRowClassName">
                            <el-table-column type="index"></el-table-column>
                            <el-table-column prop="Speed" align="center" :label="$t('DEGREE OF COMPLETION')"
                                width="130px;">
                                <template slot-scope="{row,index,size,type}">
                                    <div style="display: flex; flex-direction: row;">
                                        <el-tooltip class="item" effect="light" placement="bottom-start">
                                            <div slot="content">
                                                <div style="display: flex; flex-direction: column;">
                                                    <span v-if="row.PendingContent.CurrentPendingSummarys.length == 0"
                                                        style="font-weight: 500; color: #000; font-size: 14px;">{{
                                                                $t('To do list for this order')
                                                        }}:{{ $t('nothing') }} </span>
                                                    <span v-if="row.PendingContent.CurrentPendingSummarys.length != 0"
                                                        style="font-weight: 500; color: #000; font-size: 14px;">{{
                                                                $t('To do list for this order')
                                                        }}:</span>
                                                    <div v-for="(item, index) in row.PendingContent.CurrentPendingSummarys"
                                                        v-bind:key="index">
                                                        <span
                                                            style="font-weight: 600; color: #000; font-size: 12px; margin-left: 5px;">{{
                                                                    formatterColumn(item.Type)
                                                            }}</span>
                                                        <span v-if="item.ProcessName != ''"
                                                            style="font-weight: 600; color: #000; font-size: 12px; margin-left: 5px; margin-right: 5px;">{{
                                                                    item.ProcessName
                                                            }}</span>
                                                        <el-button v-if="item.ProcessName != ''" type="text"
                                                            @click="upload(row, $index, size, type)"
                                                            style="font-weight: 600;  font-size: 10px; margin-left: 5px; margin-right: 5px;">
                                                            {{ $t('Click to fill in') }}</el-button>
                                                    </div>
                                                    <span v-if="row.PendingContent.OtherPendingSummarys.length == 0"
                                                        style="font-weight: 500; color: #000; font-size: 14px;">{{
                                                                $t('To do list for other order')
                                                        }}:{{ $t('nothing') }} </span>
                                                    <span v-if="row.PendingContent.OtherPendingSummarys.length != 0"
                                                        style="font-weight: 500; color: #000; font-size: 14px;">{{
                                                                $t('To do list for other order')
                                                        }}:</span>
                                                    <div v-for="(item, index) in     row.PendingContent.OtherPendingSummarys"
                                                        v-bind:key="index">
                                                        <span
                                                            style="font-weight: 600; color: #000; font-size: 14px; margin-left: 5px;">{{
                                                                    item.Title
                                                            }}</span>
                                                        <div v-for="(otherlist, index) in item.OtherPendingInfos"
                                                            v-bind:key="index">
                                                            <div style="display: flex; flex-direction: column;">
                                                                <span
                                                                    style="font-weight: 400; color: #000; font-size: 10px; margin-left: 5px;">{{
                                                                            otherlist.NameCompany
                                                                    }}</span>
                                                                <span
                                                                    style="font-weight: 400; color: #000; font-size: 8px; margin-left: 5px;">{{
                                                                            otherlist.IsPO
                                                                    }}({{ otherlist.BusinessNoIn }}):{{
        otherlist.Code
}}</span>
                                                                <span
                                                                    style="font-weight: 400; color: #ff6a00; font-size: 8px; margin-left: 5px;">{{
                                                                            $t('to do a total')
                                                                    }}:{{ otherlist.Count }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <el-link type="warning">{{ row.Speed }}</el-link>
                                        </el-tooltip>
                                        <div class="d-f-c-c-t" style="margin-left: 10px;">
                                            <div class="icon">
                                                <img style="width: 25px;height: 23px;margin-right: 30px;"
                                                    v-if="row.IsToLast" src="/img/speed/linkAll.png" alt="" />
                                                <img style="width: 25px;height: 23px;margin-right: 30px;" v-else
                                                    src="/img/speed/linkNo.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="BusinessNoIn" :label="$t('BUSINESS SERIAL NUMBER INTERNAL')">
                            </el-table-column>
                            <el-table-column v-if="showOut" prop="BusinessNoOut"
                                :label="$t('BUSINESS SERIAL NUMBER EXTERNAL')"></el-table-column>
                            <el-table-column prop="Code"
                                :label="isBrandBusiness ? $t('MASTER STYLE NUMBER') : $t('FINISHED ITEM NUMBER')">
                            </el-table-column>
                            <el-table-column prop="ProductName"
                                :label="isBrandBusiness ? $t('DESCRIPTION') : $t('Product Name')"></el-table-column>
                            <el-table-column prop="Batch" :label="$t('BATCH')" v-if="!isBrandBusiness">
                            </el-table-column>
                            <el-table-column prop="Color" :label="$t('COLOR')"></el-table-column>

                            <el-table-column align="center" prop="ApproveStatus" :label="$t('STATUS')"
                                :formatter="formatterStatus" v-if="!isBrandBusiness">
                                <template slot-scope="scope">
                                    <el-popover trigger="hover" placement="top">
                                        <p> {{ $t('Statetip_' + scope.row.ApproveStatus) }}</p>
                                        <div slot="reference">
                                            <span style="margin-left: 10px">{{ formatterStatus(scope.row) }}</span>
                                        </div>
                                    </el-popover>
                                </template>
                            </el-table-column>

                            <el-table-column align="center" prop="TrackingApproveStatus" :label="$t('STATUS')"
                                :formatter="formatterTrackingStatus" v-if="isBrandBusiness">
                                <template slot-scope="scope">
                                    <el-popover trigger="hover" placement="top">
                                        <p> {{ $t('Statetip_' + scope.row.TrackingApproveStatus) }}</p>
                                        <div slot="reference">
                                            <span style="margin-left: 10px">{{ formatterTrackingStatus(scope.row)
                                            }}</span>
                                        </div>
                                    </el-popover>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" :label="$t('UPDATE TIME')">
                                <template slot-scope="scope">
                                    <el-tooltip class="item" effect="dark" placement="bottom"
                                        v-if="scope.row.IsApproveAfChange">
                                        <div slot="content">
                                            {{ $t('Modify again after approval') }}
                                        </div>
                                        <i class="el-icon-warning-outline"
                                            style="margin-left: 3px; font-size: 14px"></i>
                                    </el-tooltip>
                                    <span style="margin-left: 10px">{{ transformDate(scope.row.LinkageUpdateDateTime)
                                    }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="Remark" :label="$t('REMARKS')"></el-table-column>
                            <el-table-column width="200px;" align="center" :label="$t('OPERATION')">
                                <template slot-scope="{row,$index,size,type}" style="align-content:center">
                                    <div class="d-flex justify-content-center" style="" v-if="row.CopyDeclarationbox1">
                                        <div class="" v-if="row.CopyDeclarationbox">
                                            <el-button icon="icon-dingwei1" type="text" size="medium"
                                                :title="$t('Tracing')"
                                                v-if="LastSectionlist.indexOf(row.VendorSectionId) == -1 && row.Status == 0 && (row.CopySign != 2 && isBrandBusiness)"
                                                @click="tracingPur(row)"></el-button>
                                            <el-button icon="icon-shangchuan" type="text" size="medium"
                                                :title="$t('Upload')"
                                                v-if="row.CopySign == 2 ? false : (!isBrandBusiness && row.Status == 0)"
                                                @click="upload(row, $index, size, type)"></el-button>
                                            <el-button icon="el-icon-thumb" type="text" size="medium"
                                                :title="$t('VIEW AUDIT INFO')"
                                                v-if="row.ApproveStatus >= 401 && !isBrandBusiness"
                                                @click="ViewAuditInfo(row, $index, size, type)"></el-button>
                                            <el-button icon="el-icon-document-add" type="text" size="medium"
                                                :title="$t('CopyDeclaration')"
                                                v-if="CopyDeclarationStatus(props.row.Details.length, row.CopySign)"
                                                @click="CopyDeclarationBtn(row, props.row.Details, $index)"></el-button>
                                        </div>
                                        <div>
                                            <div class="check-cancel" v-if="row.CopySign == 2">
                                                <img src="/img/speed/noCopy.png" alt="" @click="checkCancelBtn(row)">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="d-flex" v-if="row.checkBox">
                                            <div class="check-box"
                                                :style="row.CopyDeclarationId ? 'border-color: #009eff;' : ''"
                                                v-if="row.checkBoxStatus" @click="checkBtn(row, props.row.Details)">
                                                <div v-show="row.CopyDeclarationId ? true : false"></div>
                                            </div>
                                        </div>
                                        <div class="d-flex" v-else>
                                            <p class="operation-text preservation"
                                                @click="preservationCopy(row, props.row.Details)">{{ $t("Hold") }}</p>
                                            <p class="operation-text cancel"
                                                @click="cancelCopy(row, props.row.Details)">{{ $t("Cancel") }}</p>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </avue-crud>
                <el-dialog :title="$t('ChooseUploadBrand')" :visible.sync="BranddialogTableVisible"
                    :modal-append-to-body="false" width="30%" top="1vh">
                    <el-form-item :label="$t('Brand')" label-width="120px">
                        <el-select v-model="brandChoose" filterable placeholder="" style="width: calc(100% - 115px);">
                            <el-option v-for="item in brandList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div style="text-align:center;padding:50px 0;">
                        <el-button type="primary" @click="submitBrandChoose(); BranddialogTableVisible = false;">
                            {{ $t('Save') }}</el-button>
                        <el-button @click="BranddialogTableVisible = false">{{ $t('Cancel') }}</el-button>
                    </div>
                    <el-form-item>

                    </el-form-item>
                </el-dialog>
                <el-dialog :title="$t('Audit History')" :visible.sync="RejectdialogTableVisible"
                    :modal-append-to-body="false" width="70%" top="1vh">
                    <el-form :model="RejectEdit" :rules="Rejectrules" ref="Rejectrules" label-position="top"
                        label-width="150px" v-if="!isViewAudit">
                        <el-row>
                            <el-col :span="24" :sm="24" :md="24">
                                <el-form-item :label="$t('RESUBMIT DESCRIPTION')" prop="Remarks"
                                    :rules="Rejectrules.Remarks">
                                    <el-input v-model="RejectEdit.Remarks" style="width: calc(100% - 115px);"
                                        type="textarea" maxlength="300" show-word-limit
                                        :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div style="text-align:center;padding:20px 0;">
                            <el-button type="primary" @click="submitReject('Rejectrules');">{{ $t('Save') }}</el-button>
                            <el-button @click="RejectdialogTableVisible = false">{{ $t('Cancel') }}</el-button>
                        </div>
                    </el-form>
                    <avue-crud :option="setData.tableOptRejectList" :data="tableDataRejectList" :row-style="rowStyle"
                        :page.sync="pageRejectList" @current-change="currentRejectListChange">
                    </avue-crud>
                </el-dialog>
                <el-dialog :title="$t('importPOList')" :visible.sync="dialogTableVisible" :modal-append-to-body="false"  v-loading="importLoad"
                    width="90%" top="2vh" >
                    <el-row  class="topFromSearch">
                    <el-col class="formDiv" :xs="24" :sm="18" :md="18" :lg="18" :xl="18" style="align-self: flex-end; ">
                          <el-link v-if="ImportResult.resultsType==-1"  type="danger" :underline="false">{{ $t('Total imported data')+ImportResult.cnt+$t('Total verification error data')+ImportResult.errCnt}}</el-link>
                          <el-link v-if="ImportResult.resultsType==1"  type="success" :underline="false">{{ $t('Verify that the imported data is verified. Please confirm the import')+" "+$t('total')+":"+ImportResult.cnt+$t('Contains update header total')+ImportResult.upHeadCnt+$t('Update item total')+ImportResult.upItemCnt}}</el-link>
                 </el-col>
                    <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6"
                        style="text-align: right; align-self: flex-end;">
                        <el-button v-if="ImportResult.resultsType==-1"  type="primary" icon="iconfont daochu2" size="mini" :title="$t('Exporting Verification Result')" plain
                            @click="exportVerificationResult"> {{ $t('Exporting Verification Result') }}</el-button>
                    </el-col>
                </el-row>
                    <avue-crud ref="crud" :option="setData.tableOptFile" :data="tableFileData" :row-style="rowStyle" >
                       
                    </avue-crud>
                    <el-row :gutter="0" class="topFromSearch">
                        <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20"></el-col>
                       <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"
                        style="text-align: right; align-self: flex-end;">
                             <el-button  @click="dialogTableVisible = false;dialogImportTableVisible=true;">{{$t('Return')}}</el-button>
                             <el-button  v-if="ImportResult.resultsType==1" type="primary"  @click="subUpdateRangeIpm">{{ $t('Import data') }} </el-button>
                        </el-col>
                    </el-row>
                </el-dialog>
                <el-dialog :title="$t('IMPORT PO VIA EXCEL FILE')" :visible.sync="dialogImportTableVisible"
                    :modal-append-to-body="false" width="40%" top="20vh"  v-loading="importLoad">
                    <el-row :gutter="0" class="topFromSearch">
                        <el-col class="formDiv">
                            <div class="el-message-box__status el-icon-warning"> 
                                <el-link type="primary" @click="downTemplate">{{ $t('Import the purchase order fill in the template,and download the template')}}</el-link>
                                <!--<span style="color:black;font-size:8px"></span>-->
                                <!--<el-link type="warning" >{{Templeate}}</el-link>-->
                                <!--<el-button type="primary" size="small" style="margin-left: 0px !important" plain >{{$t('Upload File')}}</el-button>-->
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="0">
                        <el-col class="formDiv" :xs="24" :sm="24" :md="24" :lg="24" :xl="14"
                            style=" float: left; display: flex; align-items: flex-start; justify-content: flex-start; padding-top: 10px; padding-left: 39px !important;">
                            <el-upload class="upload-demo" :on-change="beforeUpload" :auto-upload="false"
                                :multiple="false" :on-preview="handlePreview" :on-remove="handleRemove"
                                :before-remove="beforeRemove" accept=".xlsx" :file-list="fileList"
                                :action="targetAction">
                                <el-input suffix-icon="iconfont icon-wenjianjiaFolders5" style="width:100%;"
                                    size="medium" @click.native="uploadClick()">
                                </el-input>
                            </el-upload>
                        </el-col>

                    </el-row>
                    <el-row :gutter="0">
                        <el-col class="formDiv" :xs="24" :sm="24" :md="24" :lg="24" :xl="14"
                            style=" float: left; display: flex; align-items: flex-start; justify-content: flex-start; padding-top: 10px; padding-left: 39px !important;">
                            <el-button type="primary" size="small" icon="el-icon-plus"  @click="importOrder">{{
                                    $t('Upload File')}}</el-button>
                        </el-col>
                    </el-row>
                </el-dialog>
                <el-dialog :title="$t('exportPO')" :visible.sync="dialogExportVisible" :modal-append-to-body="false"
                    width="30%" top="20vh">
                    <el-row :gutter="0" class="topFromSearch">
                        <el-col class="formDiv">
                            <div class="el-message-box__status el-icon-warning">
                                <span style="color:black;font-size:8px">{{ $t('Maximum 10000 orders') }}</span>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="0" class="topFromSearch">
                        <el-col class="formDiv">
                            <el-button type="primary" size="small"
                                class="el-button el-button--primary el-button--small "
                                style="float:right; margin-left: 0px; margin-right: 5px !important;" @click="exportAll">
                                {{ $t('Export All') }}</el-button>
                            <el-button type="primary" size="small" class="el-button el-button--primary el-button--small"
                                style="float:right; margin-left: 0px; margin-right: 5px !important;"
                                @click="exportCurrent">{{ $t('Export Current') }}</el-button>
                        </el-col>
                    </el-row>
                </el-dialog>
            </template>
        </basic-container>
    </div>
</template>
<style>
.el-dialog__wrapper {
    transition-duration: 0.3s;
}

.notifyStyle {
    width: 30% !important;
    background: #FFE4E1;
}

.dataicon i {
    display: none;
}

.othChe .el-checkbox__label {
    color: #409eff !important;
}

.el-table tr .el-table__expanded-cell {
    padding: 0 0 0 60px;
}

.el-icon-arrow-right:before {
    content: "\E6E0";
    font-weight: bold;
    color: #000;
    font-size: 15px;
}

.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.operation-text {
    cursor: pointer;
}

.el-icon-document-add {
    margin-left: 10px;
}

.preservation {
    margin-right: 10px;
    color: #2aa6ff;
}

.cancel {
    color: red;
}

.check-box {
    width: 14px;
    height: 14px;
    border: 1px solid #A9A9A9;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}

.check-box div {
    width: 10px;
    height: 10px;
    background: #009eff;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.check-cancel {
    width: 15px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    margin-left: 10px;
    cursor: pointer;
}

.check-cancel img {
    width: 100%;
}

.d-f-c-c-t {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.d-f-c-c-t img {
    cursor: pointer;
}

/*::v-deep */
</style>
<script>
import { purOrderList,UpdateRangeIpm, purOrderListExcel, fileOrderList, importPurchaseOrderList, delHead, recoverHead, SoListByPoId, DecCompleted, UnDecCompleted, PutPurchaseOrderDetail_Copy, PutPurchaseOrderDetail_CancelCopy } from "@/api/purchaseOrd";
import { ProcessDropDownList, TracingDropDownList, TracingUnifiedIsBool, TracingUnifiedHelpReturn, TracingStringList } from "@/api/unified";
import { ListApproves, addResubmitsPo } from "@/api/orderApprove";
import { pvhApiBase } from '@/config/env'
import { getToken, getGrop } from '@/util/auth'

export default {
    data() {
        return {
            importLoad: false,
            ImportResult:[],
            importstatus: false,
            SeaCodeVal: '',
            selectBadge: null,
            isAdvancedSearch: false,
            fileList: [],
            sortVal: "",
            showOut: false,
            loading: true,
            importLoading:true,
            downUrl: '',
            notiplist: [], notificationPromise: Promise.resolve(),
            isBrandBusiness: false,
            dialogImportTableVisible: false,
            dialogExportVisible: false,
            BranddialogTableVisible: false, PoDetailId: "", vendorSectionId: "", brandList: [], brandChoose: "",
            codeVal: "",
            businessnoinVal: "",
            businessnooutVal: "",
            batchVal: "",
            colorVal: "",
            RemarkVal: "",
            poVal: "",
            beginTimeVal: "",
            endTimeVal: "",
            UTbeginTimeVal: "",
            UTendTimeVal: "",
            vendorVal: {
                ids: [],
            },
            otherStatusVal: null,
            traingStatusVal: null,
            RejectdialogTableVisible: false, tableDataRejectList: [], RejectCategoryData: [], isViewAudit: false,
            RejectEdit: {
                DetailOrderId: '',
                Remarks: '',
            },
            Rejectrules: {
                Remarks: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
            },
            ExportAllVal: false,
            vendorData: [],
            traingStatusData: [
                {
                    label: this.$t('Ordering'),
                    value: 110
                },
                {
                    label: this.$t('Submitted'),
                    value: 320
                },
                {
                    label: this.$t('Rejected'),
                    value: 410
                },
                {
                    label: this.$t('Resubmitted'),
                    value: 550
                },
                {
                    label: this.$t('Locked'),
                    value: 605
                },
                {
                    label: this.$t('Approved'),
                    value: 660
                },
                {
                    label: this.$t('Reviewed'),
                    value: 670
                },
                {
                    label: this.$t('Delete'),
                    value: -1
                }],
            dictData: [],
            seasonData: [],
            seasonYearData: [],
            sectionData: [],
            tableData: [],
            tableFileData: [],
            dialogTableVisible: false,
            targetAction: pvhApiBase + "Tracing/GetFileOrderList",
            LastSectionlist: {},
            page: {
                currentPage: 1,
                total: 0,
                layout: "total,pager,prev, next",
                background: true,
                pageSize: 10
            },
            pageRejectList: {
                currentPage: 1,
                total: 0,
                layout: "total,pager,prev, next",
                background: true,
                pageSize: 10
            },
            RejectTypeData: [
                {
                    label: this.$t('Missing Attachments'),
                    value: "Missing Attachments"
                },
                {
                    label: this.$t('Low Resolution In Attachments'),
                    value: "Low Resolution In Attachments"
                },
                {
                    label: this.$t('Non-Requested Files Are Uploaded'),
                    value: "Non-Requested Files Are Uploaded"
                },
                {
                    label: this.$t('Filled In The Wrong Content'),
                    value: "Filled In The Wrong Content"
                },

                {
                    label: this.$t('Incomplete Content'),
                    value: "Incomplete Content"
                },
                {
                    label: this.$t('Other'),
                    value: "Other"
                }],
            RejectStatusData: [
                {
                    label: this.$t('Rejected'),
                    value: 0
                },
                {
                    label: this.$t('Rejected'),
                    value: 410
                },
                {
                    label: this.$t('RECALL'),
                    value: 420
                },
                {
                    label: this.$t('Resubmitted'),
                    value: 550
                },
                {
                    label: this.$t('Locked'),
                    value: 605
                },
                {
                    label: this.$t('Approved'),
                    value: 660
                },
                {
                    label: this.$t('Reviewed'),
                    value: 670
                }],
                  YesNoData: [
                    {
                        label: this.$t('False'),
                        value: false
                    }, {
                        label: this.$t('True'),
                        value: true
                    }, {
                        label: this.$t('True'),
                        value: 1
                    }
                    , {
                        label: this.$t('False'),
                        value: 2
                    }
                    , {
                        label: this.$t('False'),
                        value: 0
                    }
                ],
        }
    },
    computed: {
        setData() {
            return {
                tableOptRejectList: {
                    page: false,
                    refreshBtn: false,
                    columnBtn: false,
                    simplePage: true,
                    addBtn: false,
                    addRowBtn: false,
                    editBtn: false,
                    delBtn: false,
                    cellBtn: false,
                    index: true,
                    menu: false,
                    indexLabel: ' ',
                    border: true,
                    align: "center",
                    emptyText: this.$t('No Data'),
                    tip: false,
                    column: [
                        {
                            label: this.$t('DATE'),
                            prop: 'CreateTime',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: this.$t('STATE'),
                            prop: 'Status',
                            placeholder: ' ',
                            type: "select",
                            dicData: this.traingStatusData,
                        },
                        {
                            label: this.$t('ORDERS COMPANY'),
                            prop: 'ApprovedObject',
                        },
                        {
                            label: this.$t('REJECTION CATEGORY'),
                            prop: 'RejectCategory',
                            placeholder: ' ',
                            type: "select",
                            dicData: this.RejectTypeData,
                        },
                        {
                            label: this.$t('REJECTION DESCRIPTION'),
                            prop: 'RejectRemark',
                        },
                        {
                            label: this.$t('RESUBMISSION INSTRUCTIONS'),
                            prop: 'Remarks',
                        },
                    ]
                },
                tableOpt: {
                    page: true,
                    refreshBtn: false,
                    addBtn: false,
                    editBtn: false,
                    delBtn: false,
                    emptyText: this.$t('No Data'),
                    menuTitle: this.$t('OPERATION'),
                    menuWidth: 200,
                    align: 'center',
                    columnBtn: false,
                    simplePage: true,
                    expand: true,
                    rowKey: 'Id',
                    expandRowKeys: [],
                    column: [

                        {
                            label: this.$t('DEGREE OF COMPLETION'),
                            prop: 'Speed',
                            headerslot: true,
                            width: 140,
                            slot: true
                        },
                        {
                            label: this.$t('VendorSection'),
                            prop: 'VendorSectionId',
                            type: "select",
                            filterable: true,
                            dicData: this.sectionData,
                        },
                        {
                            label: this.isBrandBusiness ? this.$t('VENDOR') : this.$t('SUPPLIER'),
                            prop: 'VendorCorporationId',
                            type: "select",
                            filterable: true,
                            dicData: this.vendorData,

                        },
                        {
                            label: this.isBrandBusiness ? this.$t('MASTER PO NUMBER') : this.$t('PO'),
                            prop: 'Po',
                        },
                        {
                            label: this.$t('Brand'),
                            prop: 'Brand',
                            type: "select",
                            filterable: true,
                            dicData: this.dictData,
                        },
                        {
                            label: this.$t('SEASON'),
                            prop: 'Season',
                            width: 120,
                            type: "select",
                            filterable: true,
                            dicData: this.seasonData,
                            hide: !this.isBrandBusiness,
                        },
                        {
                            label: this.$t('YEAR'),
                            prop: 'SeasonYear',
                            width: 120,
                            type: "select",
                            filterable: true,
                            dicData: this.seasonYearData,
                            hide: !this.isBrandBusiness,
                        },
                        {
                            label: this.$t('ACDate'),
                            prop: 'ACDate',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                            width: 120,
                            hide: !this.isBrandBusiness,

                        },
                        {
                            label: this.$t('STATE'),
                            prop: 'ApproveStatus',
                            placeholder: ' ',
                            type: "select",
                            editDisplay: false,
                            hide: this.isBrandBusiness,
                            dicData: this.traingStatusData,
                            slot: true

                        },
                        {
                            label: this.$t('STATE'),
                            prop: 'TrackingApproveStatus',
                            placeholder: ' ',
                            type: "select",
                            hide: !this.isBrandBusiness,
                            editDisplay: false,
                            dicData: this.traingStatusData,
                            slot: true
                        },
                        {
                            label: this.$t('ORDER DATE'),
                            prop: 'OrderDate',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                            sortable: true,
                            width: 120,
                        },

                        {
                            label: this.$t('UPDATE TIME'),
                            prop: 'LinkageUpdateDateTime',
                            type: "date",
                            format: "yyyy-MM-dd HH:mm:ss",
                            valueFormat: "yyyy-MM-dd HH:mm:ss",
                            sortable: true,
                            width: 130,
                        },
                        {
                            label: this.$t('systemBatchNo'),
                            prop: 'SystemBatchNo',
                            hide: true
                        },
                        {
                            label: this.$t('REMARKS'),
                            prop: 'Remark',
                        }
                    ]
                },
                tableOptFile: {
                    page: false,
                    height: 600,
                    refreshBtn: false,
                    addBtn: false,
                    editBtn: false,
                    delBtn: false,
                    menu: false,
                    emptyText: this.$t('No Data'),
                    menuWidth: 200,
                    align: 'center',
                    columnBtn: false,
                    simplePage: true,
                    expand: false,
                    rowKey: 'ExtNo',
                    expandRowKeys: [],
                    column: [
                        {
                            label: this.$t('VERIFY ERROR'),
                            prop: 'ErrorInfo',
                            width:400
                        },

                        {
                            label: this.$t('IS UPDATE HEADER'),
                            prop: 'isHeadEdit',
                            type: "select",
                            dicData: this.YesNoData,
                        },
                       {
                            label: this.$t('IS UPDATE ITEM'),
                            prop: 'isItemEdit',
                            type: "select",
                            dicData: this.YesNoData,
                        },
                        {
                            label: this.$t('BUSINESS SERIAL NUMBER INTERNAL'),
                            prop: 'ExtIn',
                        },
                        {
                            label: this.$t('BUSINESS SERIAL NUMBER EXTERNAL'),
                            prop: 'ExtNo',
                        },
                        {
                            label: this.$t('PO'),
                            prop: 'Po',

                        },
                        {
                            label: this.$t('VendorSection'),
                            prop: 'VendorSectionCode',
                        },
                        {
                            label: this.$t('Supplier Code'),
                            prop: 'SupplierCode',
                        },
                        {
                            label: this.$t('Supplier'),
                            prop: 'SupplierName',
                        },
                        {
                            label: this.$t('Batch'),
                            prop: 'Batch',
                        },
                        {
                            label: this.$t('Brand'),
                            prop: 'Brand',
                        },
                        {
                            label: this.$t('Division'),
                            prop: 'Division',
                        },
                        {
                            label: this.$t('ORDER DATE'),
                            prop: 'OrderDate',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: this.$t('ContractNo'),
                            prop: 'ContractCode',
                        },
                        {
                            label: this.$t('Item Batch'),
                            prop: 'ItemBatch',
                        },
                        {
                            label: this.$t('Product Name'),
                            prop: 'ProductName',
                        },
                        {
                            label: this.$t('Business Code'),
                            prop: 'Code',
                        },
                        {
                            label: this.$t('COLOR'),
                            prop: 'Color',
                        },
                        {
                            label: this.$t('Number'),
                            prop: 'Number',
                        },
                        {
                            label: this.$t('UNIT'),
                            prop: 'Unit',
                        },
                        {
                            label: this.$t('ORIGIN'),
                            prop: 'Origin',
                        },
                        {
                            label: this.$t('FIBER TYPE'),
                            prop: 'FiberType',
                        },
                        {
                            label: this.$t('Affiliated Corporation Collection'),
                            prop: 'AffiliatedCorpCollection',
                        },
                        {
                            label: this.$t('Affiliated Corporation Working'),
                            prop: 'AffiliatedCorpWorking',
                        }

                    ]
                }
            }
        }
    },
    created() {
        this.init()
    },
    watch: {
        $route() {
            this.init()
        }
    },
    methods: {
        getSelectBadge() {
            this.selectBadge = 0;
            if (this.codeVal != "") {
                ++this.selectBadge;
            };
            if (this.businessnooutVal != "") {
                ++this.selectBadge;
            };
            if (this.businessnoinVal != "") {
                ++this.selectBadge;
            };
            if (this.batchVal != "") {
                ++this.selectBadge;
            };
            if (this.colorVal != "") {
                ++this.selectBadge;
            };
            if (this.RemarkVal != "") {
                ++this.selectBadge;
            };
            if (this.poVal != "") {
                ++this.selectBadge;
            };
            if (this.beginTimeVal != "") {
                ++this.selectBadge;
            };
            if (this.endTimeVal != "") {
                ++this.selectBadge;
            };
            if (this.UTbeginTimeVal != "") {
                ++this.selectBadge;
            };
            if (this.UTendTimeVal != "") {
                ++this.selectBadge;
            };
            if (this.vendorVal.ids.length != 0) {
                ++this.selectBadge;
            };
            if (this.otherStatusVal != null) {
                ++this.selectBadge;
            };
            if (this.traingStatusVal != null) {
                ++this.selectBadge;
            };
            if (this.selectBadge == 0) {
                this.selectBadge = null;
            }
        },
        tableRowClassName({ row, rowIndex }) {
            if (row.CopySign === 2) {
                return 'warning-row';
            }
            return '';
        },
        //设置表头行的样式
        tableHeaderColor() {
            return 'background-color:#838bb3!important;padding: 0!important;'

        },//表体颜色
        rowStyle(rowIndex) {
            if (rowIndex % 2 === 0) {
                return {
                    backgroundColor: '#F8F8F8'
                }
            }
        },
        init() {
            //排序说明三位分别是：创建时间，订单时间，联动更新时间
            //每位值为：0-升序，1-降序，2-无
            this.sortVal = "122"
            this.getPurOrdList();
            TracingDropDownList('VendorList').then(res => {
                this.vendorData = res.data;
            });
            TracingDropDownList('all_VendorSectionList').then(res => {
                this.sectionData = res.data;
            });
            ProcessDropDownList('DictList', 'brand').then(res => {
                this.dictData = res.data;
            });
            ProcessDropDownList('DictList', 'season').then(res => {
                this.seasonData = res.data;
            });
            ProcessDropDownList('DictList', 'season_year').then(res => {
                this.seasonYearData = res.data;
            });
            TracingUnifiedIsBool("IsBrandBusiness").then(res => {
                this.isBrandBusiness = res.data;
            });
            TracingStringList("LastSection").then(res => {
                this.LastSectionlist = res.data;
            })
        },
        focusDiv(obj) {//搜索框获取焦点
            if (obj.currentTarget === undefined) {
                obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
            } else {
                if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                    obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                } else {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }

            }
        },//搜索框失去焦点
        blurDiv(obj) {
            console.log("obj.currentTarget", obj.currentTarget);
            if (obj.currentTarget === undefined) {
                if (obj.value === "") {
                    obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                }
            } else if (obj.currentTarget === null) {
                if (this.vendorVal.ids.length === 0) {
                    document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                }
                if (this.otherStatusVal == null || this.otherStatusVal == '') {
                    document.querySelector("#otherStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                }
                if (this.traingStatusVal == null || this.traingStatusVal == '') {
                    document.querySelector("#traingStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                }
            } else {
                if (obj.target.value == "") {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                }
            }
        },
        venchang() {
            if (this.vendorVal.ids.length === 0) {
                document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
            } else {
                document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
            }
        },//点击更多按钮
        otherStatuschang() {
            if (this.otherStatusVal === 0) {
                document.querySelector("#otherStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
            } else {
                document.querySelector("#otherStatusSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
            }
        },//点击更多按钮
        traingStatuschang() {
            if (this.traingStatusVal === 0) {
                document.querySelector("#traingStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
            } else {
                document.querySelector("#traingStatusSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
            }
        },//点击更多按钮
        moreFrom() {
            let fromHei = document.querySelector(".fromOutDiv").clientHeight;
            if (fromHei > 60) {
                document.querySelector(".fromOutDiv").style.cssText = "height:60px;";
            } else {
                document.querySelector(".fromOutDiv").style.cssText = "height:auto;";
            }
        },//切换分页
        currentChange(val) {
            this.page.currentPage = val
            this.getPurOrdList();
        },//搜索
        searchFrom() {
            this.getPurOrdList();
        },//新增
        addPurOrd() {
            this.$router.push({ path: '/pvh_factory/purchaseAdd', query: { sign: 'add', ID: '', corpid: '', systemBatchNo: '' } })
        },//删除数据
        delPur(row) {
            console.log("row0", row);
            //如果使用的数据不能删除
            SoListByPoId(row.Id).then(res => {
                var list = res.data;
                if (list.length > 0) { this.$message({ showClose: true, message: this.$t('NotCanDeletedPO'), type: "warning" }); return; }
                this.$confirm(this.$t('IsDel') + row.Po, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    delHead(row.Id).then(() => {
                        this.getPurOrdList();
                        this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                    });
                });
            })
        },//恢复数据
        recover(row) {
            this.$confirm(this.$t('IsRecover') + row.Po, this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: "warning"
            }).then(() => {
                recoverHead(row.Id).then(() => {
                    this.getPurOrdList();
                    this.$message({ showClose: true, message: this.$t('RecoverSuccess'), type: "success" });
                });
            })
        },//修改
        editPur(row) {
            if (row.Status == 1) { this.$message({ showClose: true, message: this.$t('NotCanDeleted'), type: "warning" }); return; }
            this.$router.push({ path: '/pvh_factory/purchaseAdd', query: { sign: 'edit', ID: row.Id, corpid: row.CorporationId, systemBatchNo: row.SystemBatchNo } })
        },//追踪
        tracingPur(row) {
            this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: row.Id , TracingNo: row.BusinessNoIn} })
        },//获取采购订单列表
        gettip() {
            this.notificationPromise = this.notificationPromise.then(this.$nextTick).then(() => {
                let newDatas = [];
                const h = this.$createElement;
                for (let i in this.notiplist) {
                    newDatas.push(h('p', null, this.notiplist[i]));
                }
                this.$notify.error({
                    title: this.$t('OperationFailed'),
                    offset: 100,
                    showClose: true,
                    duration: 0,
                    message: h('div', null, newDatas),
                    customClass: 'notifyStyle',
                });
            });
        },

        async getPurOrdList() {
            await purOrderList(this.page.currentPage, this.page.pageSize, this.vendorVal.ids.toString(), this.businessnoinVal, this.businessnooutVal, this.codeVal, this.batchVal, this.colorVal, this.poVal, this.beginTimeVal, this.endTimeVal, '', this.otherStatusVal, this.traingStatusVal, this.UTbeginTimeVal, this.UTendTimeVal, this.sortVal, this.RemarkVal, this.SeaCodeVal).then(res => {
                this.loading = false;
                res.data.PurchaseOrders.forEach((f, index) => {
                    f.Details.forEach((f1) => {
                        f1.parentIndex = index;
                        f1.CopyDeclarationbox = true;
                        f1.CopyDeclarationbox1 = true;
                        f1.checkBox = true;
                        f1.checkBoxStatus = true;
                        f1.fromData = []
                    })
                })
                this.tableData = res.data.PurchaseOrders;
                this.page.total = res.data.TotalCount;
                this.page.pageSize = res.data.PageSize;
            }).catch(erro => {
                console.log(erro)
            })
        },
        async getPurOrdListExcel(ExportAllVal) {
            this.downUrl = pvhApiBase + "Tracing/GetPurchaseOrdersExcel?page=" + this.page.currentPage + "&pageSize=" + this.page.pageSize + "&vendor=" + this.vendorVal.ids.toString() + "&businessnoin=" + this.businessnoinVal + "&businessnoout=" + this.businessnooutVal + "&code=" + this.codeVal + "&batch=" + this.batchVal + "&color=" + this.colorVal + "&po=" + this.poVal + "&beginOrderDate=" + this.beginTimeVal + "&endOrderDate=" + this.endTimeVal + "&id=" + (this.otherStatusVal == null ? "" : "&otherStatus=" + this.otherStatusVal) + (this.traingStatusVal == null ? "" : "&traingStatus =" + this.traingStatusVal) + "&UTbegin=" + this.UTbeginTimeVal + "&UTend=" + this.UTendTimeVal + "&sort=" + this.sortVal + "&remark=" + this.UTendTimeVal + "&SeaCode=" + this.SeaCodeVal + "&exportAll=" + ExportAllVal;

            let dt = new Date();
            var filename = dt.getFullYear() + '-' +
                ((dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1)) + '-' +
                (dt.getDate() < 10 ? ('0' + dt.getDate()) : dt.getDate()) + ' ' +
                (dt.getHours() < 10 ? ('0' + dt.getHours()) : dt.getHours()) + ':' +
                (dt.getMinutes() < 10 ? ('0' + dt.getMinutes()) : dt.getMinutes()) + ':' +
                (dt.getSeconds() < 10 ? ('0' + dt.getSeconds()) : dt.getSeconds())
            this.downloadExcel(this.downUrl, "po" + filename);
        },
        exportOrder() {
            this.dialogExportVisible = true;
            //this.$confirm( this.$t('Maximum 10000 orders') , this.$t('Tips'), {
            //    confirmButtonText: this.$t('Yes'),
            //    cancelButtonText: this.$t('Cancel'),
            //    type: "warning"
            //}).then(() => {
            //    this.getPurOrdListExcel();
            //})
        },
        exportCurrent() {
            this.dialogExportVisible = false;
            this.getPurOrdListExcel(false);
        },
        exportAll() {
            this.dialogExportVisible = false;
            this.getPurOrdListExcel(true);
        },
        downloadExcel(url, fileName) {
            fetch(url, {
                headers: {
                    Authorization: 'Bearer ' + getToken(),
                    'corpId': getGrop()
                }
            }).then(function (response) {
                if (response.ok) {
                    return response.arrayBuffer()
                }
                throw new Error('Network response was not ok.')
            }).then(function (arraybuffer) {
                let blob = new Blob([arraybuffer], {
                    type: `application/vnd.ms-excel`
                })
                let objectURL = URL.createObjectURL(blob)
                let downEle = document.createElement('a')
                let fname = fileName // 下载文件的名字
                // let fname = `download` // 下载文件的名字
                downEle.href = objectURL
                downEle.setAttribute('download', fname + '.xlsx')
                document.body.appendChild(downEle)
                downEle.click()
            }).catch(function (error) {
                console.log('There has been a problem with your fetch operation: ', error.message)
            })
        },
        formatterStatus(row) {
            var s_Status = this.GetStatusName(row.ApproveStatus);
            return s_Status;
        },
        formatterTrackingStatus(row) {
            var s_Status = this.GetStatusName(row.TrackingApproveStatus);
            return s_Status;
        }
        , formatterColumnRemark(e) {
            return e.remark;
        }
        , formatterColumn(e) {
            var helpReturn = e;
            if (helpReturn == "HaveLast") {
                return this.$t('HaveLast');
            }
            else if (helpReturn == "MissedOrders") {
                return this.$t('MissedOrders');
            }
            else if (helpReturn == "HaveUpinfo") {
                return this.$t('HaveUpinfo');
            }
            else if (helpReturn == "NoUpinfo") {
                return this.$t('NoUpinfo');
            }
            else if (helpReturn == "HavePartUpinfo") {
                return this.$t('HavePartUpinfo');
            }
            else if (helpReturn == "NoWriteSO") {
                return this.$t('NoWriteSO');
            }
            else if (helpReturn == "HaveWriteSO") {
                return this.$t('HaveWriteSO');
            }
            else if (helpReturn == "NoWriteComponent") {
                return this.$t('NoWriteComponent');
            }
            else if (helpReturn == "HaveWriteComponent") {
                return this.$t('HaveWriteComponent');
            }
            else if (helpReturn == "NoRelation") {
                return this.$t('NoRelation');
            }
            else if (helpReturn == "HaveRelation") {
                return this.$t('HaveRelation');
            }
            else if (helpReturn == "NoNeedUpinfo") {
                return this.$t('NoNeedUpinfo');
            }
            else if (helpReturn == "NoLast") {
                return this.$t('NoLast');
            }
            else {
                return "";
            }
        }
        , upload(row) {
            this.vendorSectionId = row.VendorSectionId;
            //上传前判断是否有品牌商信息
            TracingDropDownList('OrderBrandTrackList', row.Id).then(res => {
                this.brandList = res.data;
                if (this.brandList.length > 0) {
                    if (this.brandList.length == 1) {
                        this.$router.push({ path: '/pvh_factory/declarationEntry', query: { orderId: row.Id, brandBusinessId: this.brandList[0].value, vendorSectionId: this.vendorSectionId, isPO: 'true' } })
                    }
                    else {
                        this.PoDetailId = row.Id;
                        this.BranddialogTableVisible = true;
                    }
                }
                else {
                    this.$message({
                        message: this.$t('NoBrandInfo'),
                        type: "warning"
                    });
                    return;
                }
            });
            //如果没有弹窗提示不能上传
            //如果只有一个上传跳转上传界面
            //如果多个弹出选择框
        },
        submitBrandChoose() {
            if (this.brandChoose == "") {
                this.$message({
                    message: this.$t('PleaseChooseBrand'),
                    type: "warning"
                });
                return;
            }
            else {
                this.$router.push({ path: '/pvh_factory/declarationEntry', query: { orderId: this.PoDetailId, brandBusinessId: this.brandChoose, vendorSectionId: this.vendorSectionId, isPO: 'true' } })
            }

        },
        CopyDeclarationStatus(length, CopySign) {
            if (length == 1) {
                return false;
            }

            return CopySign == 0 ? true : CopySign == 1 ? true : false
        },
        CopyDeclarationBtn(row, data, index) {
            data.forEach((f, findex) => {
                if (f.CopyDeclarationId == row.Id) {
                    f.checkBoxStatus = true;
                } else {
                    if (f.CopyDeclarationId) {
                        f.checkBoxStatus = false;
                    }
                }
                // console.log(f.CopyDeclarationId, findex, row.Id)
                f.CopyDeclarationbox1 = false;
            });
            row.checkBox = false
            row.CopyDeclarationbox1 = false;
        },
        cancelCopy(row, data) { /*copy 取消*/
            data.forEach((f) => {
                f.CopyDeclarationbox1 = true;
                f.checkBox = true
            });
        },
        checkCancelBtn(row) { /*取消复制*/
            this.loading = true;
            PutPurchaseOrderDetail_CancelCopy(row.Id).then(() => {
                this.getPurOrdList();
            })
        },
        preservationCopy(row, rowData) { /*copy 保存*/
            row.CopyDeclarationbox1 = true
            this.loading = true;
            var data = {
                id: row.Id,
                copyIds: [row.Id]
            }

            rowData.forEach((f) => {
                if (row.Id == f.CopyDeclarationId) {
                    data.copyIds.push(f.Id)
                }
            })

            PutPurchaseOrderDetail_Copy(data).then(() => {
                // console.log(response);
                this.getPurOrdList();
            })
        },
        checkBtn(row, data) {
            data.forEach((f) => {
                if (!f.checkBox) {
                    if (row.CopyDeclarationId) {
                        console.log('剔除')
                        row.CopyDeclarationId = ''
                        f.fromData.forEach((z, index) => {
                            console.log(z, row.Id)
                            if (z == row.Id) {
                                f.fromData.splice(index, 1)
                            }
                        });
                    } else {
                        console.log('加入')
                        f.fromData.push(row.Id)
                        row.CopyDeclarationId = f.Id
                    }
                }
            })
        },
        ViewAuditInfo(row) {
            var info = row;
            this.RejectdialogTableVisible = true;
            this.RejectEdit.DetailOrderId = info.Id;
            this.RejectEdit.Remarks = '';
            this.getRejectList();
            if (info.ApproveStatus > 605) {
                this.isViewAudit = true;
            }
            else {
                this.isViewAudit = false;
            }
        },
        submitReject(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let paramrow = {
                        detailOrderId: this.RejectEdit.DetailOrderId,
                        remarks: this.RejectEdit.Remarks,
                        status: 550,
                    };
                    addResubmitsPo(paramrow).then(() => {
                        this.$message({
                            message: this.$t('OperationSuccess'),
                            type: "success"
                        });
                        this.getPurOrdList();
                        this.RejectdialogTableVisible = false;
                    }).catch((erro) => { console.log(erro) });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async getRejectList() {
            await ListApproves(this.RejectEdit.DetailOrderId, this.pageRejectList.currentPage, this.pageRejectList.pageSize).then(res => {
                var RejectInfo = res.data.OrderApproves;
                this.tableDataRejectList = RejectInfo;
                this.pageRejectList.total = res.data.TotalCount;
                this.pageRejectList.pageSize = res.data.PageSize;
            }).catch(erro => {
                console.log(erro)
            })
        },
        currentRejectListChange(val) {
            this.pageRejectList.currentPage = val
            this.getRejectList();
        },
        //替换表格时间格式
        formatDate(row, column) {
            // 获取单元格数据
            let date = row[column.property];
            if (date) {
                return this.transformDate(date)
            } else {
                return ''
            }
        },
        //标准时间格式转yyyy-MM-dd HH:mm:ss
        transformDate(date) {
            if (date) {
                let dt = new Date(date);
                return dt.getFullYear() + '-' +
                    ((dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1)) + '-' +
                    (dt.getDate() < 10 ? ('0' + dt.getDate()) : dt.getDate()) + ' ' +
                    (dt.getHours() < 10 ? ('0' + dt.getHours()) : dt.getHours()) + ':' +
                    (dt.getMinutes() < 10 ? ('0' + dt.getMinutes()) : dt.getMinutes()) + ':' +
                    (dt.getSeconds() < 10 ? ('0' + dt.getSeconds()) : dt.getSeconds())
            } else {
                return ''
            }
        },
        openimport() {
            this.dialogImportTableVisible = true;
            this.fileList = [];
        },
        subUpdateRangeIpm()  {
            if(this.ImportResult.resultsType==1)
            {
                this.importLoad=true;
                 UpdateRangeIpm(this.ImportResult.rediskey).then(() => {
                                        this.$message({ message: this.$t('The import is successful, and the imported data is total')+this.ImportResult.cnt, type: 'success'});
                                        this.importLoad=false;
                                        this.dialogTableVisible = false;
                                        this.getPurOrdList();
                    });
            }else
            {
                    this.$message({ message: this.$t('There is no data in the imported Excel'), type: 'error'});
            }
           },
        importOrder() {
            if (this.fileList.length === 0) {
                this.$message.warning(this.$t('Please upload the file'));
            } else {
                this.importLoad=true;
                this.fileList.forEach(file => {
                      const headers = {
                                'Content-type': 'multipart/form-data',
                                 Authorization: 'Bearer ' + getToken(),
                                'corpId': getGrop()
                            }

                    let formData = new FormData(); //  用FormData存放上传文件
                    formData.append("file", file); //文件
                    formData.append("ImpType", "PO"); //文件
                    this.$axios({
                        method: "post",
                        url: pvhApiBase + "Tracing/CheckImpInfo",
                        headers: headers,
                        data: formData
                    }).then(
                        res => {
                            this.importLoad=false;
                            this.importLoading=false;
                            this.ImportResult= res.data;
                            if(this.ImportResult.resultsType==-2) //上传excle 没有数据
                            {
                                this.$message({ message: this.$t('There is no data in the imported Excel'), type: 'error'});
                            }
                            else if(this.ImportResult.resultsType==-1)
                            {
                               this.$message({ message: this.$t('Total imported data')+this.ImportResult.cnt+this.$t('Total verification error data')+this.ImportResult.errCnt, type: 'error'});
                               this.tableFileData = this.ImportResult.Results;
                               this.dialogTableVisible = true;
                               this.dialogImportTableVisible = false;
                            }
                            else if(this.ImportResult.resultsType==1)
                            {
                               this.$message({ message: this.$t('The import is successful, and the imported data is total')+this.ImportResult.cnt, type: 'success'});
                               this.tableFileData = this.ImportResult.Results;
                               this.dialogTableVisible = true;
                               this.dialogImportTableVisible = false;
                               this.getPurOrdList();
                            }
                         
                        }, err => {
                            console.log(err);
                        });
                });
            }
        },
        exportVerificationResult()
        {
            this.downloadExcel(pvhApiBase + "Tracing/ExportVerificationResult?Url="+this.ImportResult.errorFileName, "ExportVerificationResult");

        },
        downTemplate() {
            this.downUrl = pvhApiBase + "Tracing/GetPOTemplateExcelFilePathStream";
            this.downloadExcel(this.downUrl, "ImportPOTemplate");
        },
        uploadClick() {

        },
        beforeUpload(file, fileList) {
            var extension = file.name.split('.').pop().toLowerCase();
            if (extension !== 'xls' && extension !== 'xlsx') {
                this.$message.warning('只能上传后缀是.xls和.xlsx的文件');
                return;
            }
            // 文件大小限制为10M
            const fileLimit = file.size / 1024 / 1024 > 10;
            if (fileLimit) {
                setTimeout(() => {
                    this.$message.error(this.$t('UploadFailed') + ":[" + file.name + "]," + this.$t('UploadRestrictionsC'));
                }, 1000);
                return;
            }
            this.fileList = [];
            this.fileList.push(file.raw);
        },
        handleRemove(file) {
            if (this.isLocked) {
                this.$message({ message: this.$t('cantdelete'), type: "error" });
                return false;
            }
            if (file.status == "success") {
                this.fileList = [];
                this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
            }
        },
        beforeRemove(file) {
            if (this.isLocked) {
                this.$message({ message: this.$t('cantdelete'), type: "error" });
                return false;
            }
            return this.$confirm(this.$t('IsDel') + file.name, this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: "warning"
            }).then(() => {
            })
        },
        handlePreview(file) {

        },
        sortChange(val) {
            //排序说明三位分别是：创建时间，订单时间，联动更新时间
            //每位值为：0-升序，1-降序，2-无
            if (val.prop == "OrderDate") {
                if (val.order == "ascending") {
                    this.sortVal = "212";

                }
                else {
                    this.sortVal = "202";
                }
            }
            else if (val.prop == "LinkageUpdateDateTime") {
                if (val.order == "ascending") {
                    this.sortVal = "221";
                }
                else {
                    this.sortVal = "220";
                }
            }
            this.getPurOrdList();

        },
        //给状态信息
        GetStatusName(statusCode) {
            var s_Status = "";
            switch (statusCode) {
                case -1:
                    s_Status = this.$t('Delete');
                    break;
                case 0:
                    s_Status = this.$t('Ordering');
                    break;
                case 110:
                    s_Status = this.$t('Ordering');
                    break;
                case 120:
                    s_Status = this.$t('Ordering');
                    break;
                case 130:
                    s_Status = this.$t('Ordering');
                    break;
                case 150:
                    s_Status = this.$t('Ordering');
                    break;
                case 160:
                    s_Status = this.$t('Under Declaration');
                    break;
                case 320:
                    s_Status = this.$t('Submitted');
                    break;
                case 410:
                    s_Status = this.$t('Rejected');
                    break;
                case 550:
                    s_Status = this.$t('Resubmitted');
                    break;
                case 605:
                    s_Status = this.$t('Locked');
                    break;
                case 660:
                    s_Status = this.$t('Approved');
                    break;
                case 670:
                    s_Status = this.$t('Reviewed');
                    break;
                default:
                    s_Status = this.$t('unknown');
            }
            return s_Status;
        },
    }
}
</script>
